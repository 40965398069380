label {
    margin: 15px 0 5px 0;
    font-size: 1.1em;
}
.button-submit {
    margin: 20px 0;
    font-size: 1em;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    background-color: #174896;
    border: 0px solid #174896;
}
.button-submit:hover {
    background-color: #2975f0;
}
.button-copy {
    margin: 10px 0;
    font-size: .8em;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    background-color: #acacac;
    border: 1px solid #acacac;
}
.button-copy:hover {
    background-color: #dadada;
    color: #000;
}
textarea {
    width: 90%;
    height: 5em;
    padding: 7px;
    font-size: 1.1em;
    border-radius: 5px;
    border: 1px solid gray;
}
input {
    width: 90%;
    height: 2em;
    font-size: 1.1em;
    border-radius: 5px;
    border: 1px solid gray;
}
select {
    padding: 5px;
    font-size: 1.1em;
    border-radius: 5px;
    border: 1px solid gray;
}
.custom-emoji-picker {
    width: 90% !important;
    font-size: 0.7em !important;
}
.post-container{
    height: 500px;
    overflow-y: scroll;
}
.post-element{
    margin: 20px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.post-element-blocked{
    margin: 20px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: lightgray;
}